<template>
  <v-container id="promocode" fluid tag="section">
    <v-form ref="form">
      <v-container class="py-2">
        <v-row>
          <v-col cols="12" md="12">
            <label>Enter Address</label>
            <v-text-field
              id="address"
              class="purple-input"
              v-model="address"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              class="purple-input"
              label="Building Name"
              v-model="building_name"
              :rules="inputRule"
              :disabled="true"
            />
          </v-col>

          <v-col cols="6" md="6">
            <v-text-field
              class="purple-input"
              label="state"
              v-model="state"
              :rules="inputRule"
              :disabled="true"
            />
          </v-col>

          <v-col cols="6" md="6">
            <v-text-field
              class="purple-input"
              label="country"
              v-model="country"
              :rules="inputRule"
              :disabled="true"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              class="purple-input"
              label="latitude"
              v-model="latitude"
              :rules="inputRule"
              :disabled="true"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              label="Longitude"
              class="purple-input"
              v-model="longitude"
              :rules="inputRule"
              :disabled="true"
            />
          </v-col>

          <v-col cols="6" md="6">
            <v-text-field
              class="purple-input"
              label="Zip Code"
              v-model="zipcode"
              :rules="inputRule"
              :disabled="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              type="button"
              color="primary"
              class="mr-0"
              @click="submit"
              :loading="loading"
            >
              {{
                onlyView == true
                  ? "Back"
                  : editPredeniedAddress == true
                  ? "Update"
                  : "Submit"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import _ from "lodash";
var GoogleAddressParser = /** @class */ (function () {
  function GoogleAddressParser(address_components) {
    this.address_components = address_components;
    this.address = {};
    this.parseAddress();
  }
  GoogleAddressParser.prototype.parseAddress = function () {
    if (!Array.isArray(this.address_components)) {
      throw Error("Address Components is not an array");
    }
    if (!this.address_components.length) {
      throw Error("Address Components is empty");
    }
    for (var i = 0; i < this.address_components.length; i++) {
      var component = this.address_components[i];
      if (this.isStreetNumber(component)) {
        this.address.street_number = component.long_name;
      }
      if (this.isStreetName(component)) {
        this.address.street_name = component.long_name;
      }
      if (this.isCity(component)) {
        this.address.city = component.long_name;
      }
      if (this.isCountry(component)) {
        this.address.country = component.long_name;
        this.address.country_code = component.short_name;
      }
      if (this.isState(component)) {
        this.address.state = component.long_name;
      }
      if (this.isPostalCode(component)) {
        this.address.postal_code = component.long_name;
      }
    }
  };
  GoogleAddressParser.prototype.isStreetNumber = function (component) {
    return component.types.includes("street_number");
  };
  GoogleAddressParser.prototype.isStreetName = function (component) {
    return component.types.includes("route");
  };
  GoogleAddressParser.prototype.isCity = function (component) {
    return component.types.includes("locality");
  };
  GoogleAddressParser.prototype.isState = function (component) {
    return component.types.includes("administrative_area_level_1");
  };
  GoogleAddressParser.prototype.isCountry = function (component) {
    return component.types.includes("country");
  };
  GoogleAddressParser.prototype.isPostalCode = function (component) {
    return component.types.includes("postal_code");
  };
  GoogleAddressParser.prototype.result = function () {
    return this.address;
  };
  return GoogleAddressParser;
})();
export default {
  props: ["id", "action"],
  data() {
    return {
      building_name: "",
      city: "",
      state: "",
      country: "",
      country_code: "",
      address: "",
      zipcode: "",
      latitude: "",
      longitude: "",
      loading: false,
      onlyView: false,
      editPredeniedAddress: null,
      inputRule: [(v) => !!v || "Field is required"],
    };
  },
  methods: {
    extractFromAdress(components, type) {
      for (var i = 0; i < components.length; i++)
        for (var j = 0; j < components[i].types.length; j++)
          if (components[i].types[j] == type) return components[i].long_name;
      return "";
    },
    getPredeniedAddressData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "predefinedAddress/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.building_name = response.data.predefinedAddress.building_name;
            this.zipcode = response.data.predefinedAddress.zipcode;
            this.address = response.data.predefinedAddress.address;
            this.latitude = response.data.predefinedAddress.latitude;
            this.longitude = response.data.predefinedAddress.longitude;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          id: this.id,
          building_name: this.building_name,
          address: this.address,
          latitude: this.latitude,
          longitude: this.longitude,
          zipcode: this.zipcode,
          city: this.city,
          state: this.state,
          country: this.country,
          country_code: this.country_code,
        };
        if (this.onlyView) {
          this.$router.push({ name: "Predefined Address" });
        } else if (this.editPredeniedAddress == true) {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "predefinedAddress/update",
              data
            )
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Predefined Address",
                  params: { edit: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
            });
        } else {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "predefinedAddress/create",
              data
            )
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Predefined Address",
                  params: { add: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      }
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getPredeniedAddressData(this.id);
      this.editPredeniedAddress = true;
    } else {
      this.editPredeniedAddress = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }

    const places = new google.maps.places.Autocomplete(
      document.getElementById("address")
    );
    google.maps.event.addListener(places, "place_changed", () => {
      var place = places.getPlace();
      this.address = place.formatted_address;
      console.log(place);
      if (place.geometry) {
        this.latitude = place.geometry["location"].lat();
        this.longitude = place.geometry["location"].lng();
        const formatted_result = new GoogleAddressParser(
          place.address_components
        ).result();
        this.building_name = place.name;
        this.country = formatted_result.country;
        this.state = formatted_result.state;
        this.city = formatted_result.city;
        this.zipcode = formatted_result.postal_code;
        this.country_code = formatted_result.country_code;
      }
    });
  },
};
</script>

<style>
</style>
